import { useQuery } from '@apollo/client';
import React from 'react';
import { MODULES } from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import PageHeader from '../../../components/PageHeader';
import { CommentCard } from './Comments';
import { GET_COMMENT } from './graphql/Queries';

function Comment({ match: { params } }) {
  const { id } = params;

  const { data, loading } = useQuery(GET_COMMENT, {
    fetchPolicy: 'network-only',
    variables: {
      where: { id }
    }
  });

  const comment = data?.commentAdmin;

  return (
    <>
      <PageHeader menu={MODULES?.MODERATION} />
      {loading && <LoaderComponent />}
      {!loading && (
        <div className="ant-body-scroll assets-wrapper">
          <div className="comments-container">
            <div className="comments">
              {comment && <CommentCard {...comment} />}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Comment;
