import { gql } from '@apollo/client';

export const COMMON_ASSET_FIELDS = gql`
  fragment CommonAssetFields on Asset {
    id
    url
    blurhash
  }
`;

export const GET_TAGS = gql`
  query tagsAdmin($filter: TagsFilter) {
    tagsAdmin(filter: $filter) {
      count
      tags {
        id
        name
      }
    }
  }
`;

export const GET_TOPICS = gql`
  query topicsAdmin($filter: TopicsFilter) {
    topicsAdmin(filter: $filter) {
      count
      topics {
        id
        name
        primaryColor
      }
    }
  }
`;

export const GET_CONTRIBUTORS = gql`
  query contributorsAdmin(
    $offset: Int
    $limit: Limit
    $search: String = ""
    $isActive: Boolean = true
  ) {
    contributorsAdmin(
      filter: { skip: $offset, limit: $limit, search: $search }
      where: { isActive: $isActive }
    ) {
      count
      contributors {
        id
        firstName
        lastName
        primaryColor
        image {
          url
          blurhash
        }
      }
    }
  }
`;

export const SCHEDULE_FRAGMENT = gql`
  fragment ScheduleFragment on Schedule {
    date
    days
    endTime
    mode
    startTime
    timeZone
  }
`;

export const GET_VIDEO = gql`
  ${COMMON_ASSET_FIELDS}
  ${SCHEDULE_FRAGMENT}
  query videoAdmin($id: ID!) {
    videoAdmin(where: { id: $id }) {
      title
      description
      slug
      tags {
        id
        name
      }
      topics {
        id
        name
      }
      sources {
        id
        name
        image {
          id
          url
        }
      }
      isLive
      allowedPlans {
        key
        expiredAt
      }
      status
      contentRating
      autoPublishAt
      metaHeader
      metaFooter
      speakers {
        id
        firstName
        lastName
        image {
          url
        }
        primaryColor
      }
      liveStatus
      video: videoAsset {
        id
        serviceVideoThumbnail
        serviceImageThumbnail
        isLive
      }
      image {
        ...CommonAssetFields
      }
      imageThumbnail {
        ...CommonAssetFields
      }
      videoThumbnail {
        ...CommonAssetFields
      }
      timelineThumbnail {
        ...CommonAssetFields
      }
      documents {
        ...CommonAssetFields
        title
      }
      audioTracks {
        ...CommonAssetFields
        title
        serviceImageThumbnail
      }
      textTracks {
        ...CommonAssetFields
        title
      }
      html
      resources {
        type
        content
      }
      permissions
      isScheduled
      schedule {
        ...ScheduleFragment
      }
    }
  }
`;

export const GET_VIDEOS_MODULES = gql`
  ${SCHEDULE_FRAGMENT}
  query videosAdmin($filter: VideosFilter, $sort: VideosSort) {
    videosAdmin(filter: $filter, sort: $sort) {
      count
      videos {
        id
        title
        description
        createdAt
        slug
        imageThumbnail {
          url
          blurhash
        }
        isLive
        liveStatus
        isScheduled
        status
        schedule {
          ...ScheduleFragment
        }
      }
    }
  }
`;
