import { gql } from '@apollo/client';

export const POST_FRAGMENT = gql`
  fragment VideoFragment on Video {
    id
    title
    description
    topics {
      id
      name
      primaryColor
    }
    collections {
      id
      slug
      title
    }
    speakers {
      id
      firstName
      lastName
      image {
        url
        blurhash
      }
    }
    tags {
      id
      name
    }
    createdAt
    video: videoAsset {
      id
      url
      information {
        durationInSeconds
      }
    }
    videoThumbnail {
      url
      blurhash
    }
  }

  fragment PodcastFragment on Podcast {
    id
    title
    description
    slug
    topics {
      id
      name
      slug
      primaryColor
    }
    collections {
      id
      slug
      title
    }
    speakers {
      id
      firstName
      lastName
      slug
      image {
        url
        blurhash
      }
    }
    tags {
      key
      name
      slug
    }
    createdAt
    audio {
      id
      url
      information {
        durationInSeconds
      }
    }
    imageThumbnail {
      url
      blurhash
    }
    image {
      url
      blurhash
    }
  }

  fragment ArticleFragment on Article {
    publishedAt
    html
    authors {
      firstName
      lastName
      subTitle
      thumbnail {
        url
        blurhash
      }
      image {
        blurhash
        url
      }
      id
      slug
    }
    tags {
      id
      name
      key
      slug
    }
    topics {
      id
      name
      slug
      primaryColor
    }
    prayerCount
    title
    description
    slug
    commentCount
    imageThumbnail {
      id
      url
      blurhash
    }
  }

  fragment FormFragment on Form {
    id
    title
    submitCount
    description
    slug
    ctas {
      icon {
        url
        id
      }
      actionUrl
      action
      label
      type
    }
    type
    fields {
      key
      label
      order
      type
      required
      options {
        key
        label
        order
      }
    }
  }

  fragment PostFragment on Post {
    id
    text
    createdBy {
      id
      profileImage
      firstName
      lastName
    }
    createdAt
    flagCount
    prayerCount
    likeCount
    viewCount
    commentCount
    attachment {
      blurhash
      serviceImageThumbnail
      serviceVideoThumbnail
      status
      url
      id
      categoryKey
      service
      title
    }
    mediaModerationResponse
    moderationResponse
    isDeleted
    community {
      title
    }
  }
`;

export const GET_POSTS = gql`
  ${POST_FRAGMENT}
  query postsAdmin($filter: PostsFilter!, $sort: PostsSort) {
    postsAdmin(filter: $filter, sort: $sort) {
      count
      posts {
        ...PostFragment
        instanceType
        instance {
          ... on Video {
            ...VideoFragment
          }
          ... on Podcast {
            ...PodcastFragment
          }
          ... on Article {
            ...ArticleFragment
          }
          ... on Form {
            ...FormFragment
          }
          ... on Post {
            ...PostFragment
          }
        }
      }
    }
  }
`;

export const GET_POSTS_OPTIONS = gql`
  query postsAdmin($filter: PostsFilter!) {
    postsAdmin(filter: $filter) {
      count
      posts {
        id
        text
      }
    }
  }
`;

export const POSTS_MODERATION_LABELS = gql`
  query PostModerationLabels {
    postModerationLabels
  }
`;

export const GET_POST = gql`
  ${POST_FRAGMENT}
  query PostAdmin($where: PostWhereUniqueInput!) {
    postAdmin(where: $where) {
      ...PostFragment
      instanceType
      instance {
        ... on Video {
          ...VideoFragment
        }
        ... on Podcast {
          ...PodcastFragment
        }
        ... on Article {
          ...ArticleFragment
        }
        ... on Form {
          ...FormFragment
        }
        ... on Post {
          ...PostFragment
        }
      }
    }
  }
`;
