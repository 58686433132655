import { useQuery } from '@apollo/client';
import { Select } from 'antd';
import React, { useMemo } from 'react';
import { COMMENTS_MODERATION_LABELS } from '../graphql/Queries';

function SelectCommentsModeration(props) {
  const { data, loading } = useQuery(COMMENTS_MODERATION_LABELS);

  const options = useMemo(
    () => [
      { label: 'All', value: 'all' },
      ...(data?.commentModerationLabels?.map((label) => ({
        label,
        value: label
      })) ?? [])
    ],
    [data]
  );

  return (
    <Select
      allowClear
      loading={loading}
      options={options}
      placeholder="System Flag"
      popupMatchSelectWidth={false}
      {...props}
    />
  );
}

export default SelectCommentsModeration;
