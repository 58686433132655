import { gql } from '@apollo/client';

export const COMMENT_FRAGMENT = gql`
  fragment CommentFragment on Comment {
    id
    comment
    createdBy {
      id
      profileImage
      firstName
      lastName
    }
    createdAt
    likesCount
    starsCount
    replyCount
    flagsCount
    moderationResponse
    mediaModerationResponse
    mediaType
    mediaUrl
    isAccepted
    isDeleted
    isHidden
    isHighlighted
    mediaType
    mediaUrl
  }
`;

export const COMMENTS = gql`
  ${COMMENT_FRAGMENT}
  query CommentThreadAdmin($filter: CommentThreadFilter!, $sort: CommentsSort) {
    commentThreadAdmin(filter: $filter, sort: $sort) {
      count
      comments {
        ...CommentFragment
      }
    }
  }
`;

export const COMMENTS_MODERATION_LABELS = gql`
  query CommentModerationLabels {
    commentModerationLabels
  }
`;

export const GET_COMMENT = gql`
  ${COMMENT_FRAGMENT}
  query CommentAdmin($where: CommentWhereInput!) {
    commentAdmin(where: $where) {
      ...CommentFragment
    }
  }
`;
