import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import Comment from './Comment';
import Comments from './Comments';

const CommentsWrapper = () => {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES?.COMMENTS}
        render={(props) => (
          // TODO: Handle permissions once added in BE
          // <AccessControl
          //   modulePermissions={[
          //     {
          //       moduleKey: WORKSPACE_ROLE_PERMISSION.REPORT_MANAGEMENT,
          //       allowedPermissions: [
          //         WORKSPACE_ROLE_LEVEL.VIEW,
          //         WORKSPACE_ROLE_LEVEL.EDIT,
          //         WORKSPACE_ROLE_LEVEL.DELETE
          //       ]
          //     }
          //   ]}
          // >
          <Comments {...props} />
          // </AccessControl>
        )}
      />
      <Route
        exact
        path={`${ROUTES?.COMMENTS}/:id`}
        render={(props) => (
          // TODO: Handle permissions once added in BE
          // <AccessControl
          //   modulePermissions={[
          //     {
          //       moduleKey: WORKSPACE_ROLE_PERMISSION.REPORT_MANAGEMENT,
          //       allowedPermissions: [
          //         WORKSPACE_ROLE_LEVEL.VIEW,
          //         WORKSPACE_ROLE_LEVEL.EDIT,
          //         WORKSPACE_ROLE_LEVEL.DELETE
          //       ]
          //     }
          //   ]}
          // >
          <Comment {...props} />
          // </AccessControl>
        )}
      />
    </Switch>
  );
};

export default CommentsWrapper;
