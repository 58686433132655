import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { App, ConfigProvider } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom/client';
import APP from '../package.json';
import { AppContextProvider } from './AppContext';
import Routes from './Routes';
import SocketContextProvider from './SocketContext';
import client from './apollo';
import AppComponentContainer from './components/AppComponentContainer';
import './index.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './styles/main.less';
import Styles from './styles/style.json';

Sentry.init({
  dsn: process.env?.REACT_APP_SENTRY_DSN,
  environment: process.env?.REACT_APP_ENV,
  tracesSampleRate: 1.0
});

// eslint-disable-next-line no-console
console?.log('APP_VERSION ', APP.version);

// eslint-disable-next-line no-undef
const root = ReactDOM?.createRoot(document?.getElementById('root'));
root?.render(
  <ApolloProvider client={client}>
    <AppContextProvider>
      <SocketContextProvider>
        <App>
          {/* Adding antd apps container to render components */}
          <AppComponentContainer />
          <ConfigProvider theme={Styles}>
            <Routes />
          </ConfigProvider>
        </App>
      </SocketContextProvider>
    </AppContextProvider>
  </ApolloProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
// commented service worker
// serviceWorkerRegistration.register({
//   onUpdate: (registration) => {
//     if (registration?.waiting) {
//       // eslint-disable-next-line no-console
//       console?.log('Inside registration');
//       message?.info(
//         <>
//           New version available! Click Reload to get the latest version.
//           <Button
//             className="ml-1 mb-0"
//             type="link"
//             onClick={() => {
//               registration?.waiting?.postMessage({ type: 'SKIP_WAITING' });
//               // eslint-disable-next-line no-undef
//               window.location.reload();
//             }}
//           >
//             Reload
//           </Button>
//         </>,
//         0
//       );
//     }
//   }
// });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
