import { EditOutlined, EyeFilled } from '@ant-design/icons';
import { Button, Select, Tooltip } from 'antd';
import { capitalize } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  FORM_TYPES,
  FORM_TYPE_OPTIONS,
  LIST_TYPES,
  MODULES,
  ROUTES,
  STATUS_OPTIONS,
  UNPUBLISHED_STATUS,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import ContentStatus from '../../components/ContentStatus';
import FilterBar, { useFilterBar } from '../../components/FilterBar';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import useCheckPermission from '../../hooks/useCheckPermission';
import { GET_FORMS } from './graphql/Queries';

const variablesSelector = ({
  offset,
  sortField,
  sortOrder,
  search,
  limit,
  type,
  status
}) => ({
  filter: {
    skip: offset,
    search,
    limit,
    type,
    status
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const dataSelector = (data) => ({
  data: data?.formsAdmin?.forms ?? [],
  count: data?.formsAdmin?.count ?? 0
});

const Action = (record) => {
  const { id } = record;
  const history = useHistory();

  const handleEdit = () => {
    history.push({
      pathname: `${ROUTES.FORMS}/${id}/edit`
    });
  };

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  return (
    <div className="d-flex align-center">
      <Tooltip title={`${isViewOnly ? 'View' : 'Edit'} Form`}>
        <Button type="text" className="text-btn" onClick={handleEdit}>
          {isViewOnly ? <EyeFilled /> : <EditOutlined />}
        </Button>
      </Tooltip>
    </div>
  );
};

const columns = ({ isAddEditAllowed }) => [
  {
    title: 'Title',
    dataIndex: 'title',
    ellipsis: true
  },
  {
    title: 'Description',
    dataIndex: 'description',
    ellipsis: true
  },
  {
    title: 'Type',
    dataIndex: 'type',
    ellipsis: true,
    render: (value) => {
      return <p>{capitalize(value)}</p>;
    }
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (value) => <ContentStatus status={value} />
  },
  {
    title: 'Action',
    dataIndex: 'id',
    render: (_, record) => (
      <Action {...record} isAddEditAllowed={isAddEditAllowed} />
    )
  }
];

const FormList = ({ history }) => {
  const filterProps = useFilterBar();
  const [type, setType] = useState(FORM_TYPES.PETITION);
  const [status, setStatus] = useState(undefined);

  const handleTypeChange = (value) => setType(value);
  const handleStatusChange = (value) => setStatus(value);

  const filters = useMemo(() => ({ ...filterProps.filters, type, status }), [
    filterProps.filters,
    type,
    status
  ]);

  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.CONTENT_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const handleShowModal = () => {
    history.push(`${ROUTES.FORMS}/add`);
  };

  return (
    <>
      <PageHeader
        menu={MODULES.CONTENT_UNITS}
        handleShowModal={handleShowModal}
        isAddEditAllowed={isAddEditAllowed}
      />
      <FilterBar
        {...filterProps}
        SearchProps={{ placeholder: 'Search Form' }}
        show={{
          listModes: false
        }}
      >
        <Select
          value={type}
          popupMatchSelectWidth={false}
          placeholder="Select type"
          options={FORM_TYPE_OPTIONS}
          onChange={handleTypeChange}
        />
        <Select
          options={[...STATUS_OPTIONS, UNPUBLISHED_STATUS].map(
            ({ name, value }) => ({
              label: name,
              value
            })
          )}
          placeholder="Select status"
          value={status}
          onChange={handleStatusChange}
        />
      </FilterBar>
      <PageList
        filters={filters}
        listMode={LIST_TYPES.TABLE}
        query={GET_FORMS}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        TableProps={{
          columns: (props) =>
            columns({
              ...props,
              isAddEditAllowed
            })?.filter((item) => item !== false)
        }}
        limit={20}
      />
    </>
  );
};

export default FormList;
