import { Badge } from 'antd';
import React from 'react';
import { STATUS_TYPES } from '../common/constants';

const ContentStatus = ({ status }) => {
  return (
    <>
      {status === STATUS_TYPES.DRAFT && (
        <Badge className="status" count="DRAFT" color="#5E5957" />
      )}
      {status === STATUS_TYPES.PUBLISHED && (
        <Badge className="status" count="PUBLISHED" color="#52c41a" />
      )}
      {status === STATUS_TYPES.UNPUBLISHED && (
        <Badge className="status" count="UNLISTED" />
      )}
    </>
  );
};

export default ContentStatus;
