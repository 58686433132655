import { useQuery } from '@apollo/client';
import { Col, Row } from 'antd';
import React from 'react';
import { MODULES } from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import PageHeader from '../../../components/PageHeader';
import PostCard from './components/PostCard';
import { GET_POST } from './graphql/Queries';

function Post({ match: { params } }) {
  const { id } = params;

  const { data, loading } = useQuery(GET_POST, {
    fetchPolicy: 'network-only',
    variables: {
      where: { id }
    }
  });

  const post = data?.postAdmin;

  return (
    <>
      <PageHeader menu={MODULES?.MODERATION} />
      {loading && <LoaderComponent />}
      {!loading && (
        <div className="ant-body-scroll assets-wrapper">
          <div className="moderation-posts">
            <Row>
              {post && (
                <Col xs={24} lg={12}>
                  <PostCard {...post} />
                </Col>
              )}
            </Row>
          </div>
        </div>
      )}
    </>
  );
}

export default Post;
